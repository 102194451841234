import React from 'react';
import '../App.css';
import ScrollAnimation from 'react-animate-on-scroll';
import Grid from '@mui/material/Grid';
import WebIcon from '@mui/icons-material/Web';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import CodeIcon from '@mui/icons-material/Code';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { ScrollEffect } from 'react-easy-scroll-effect'
import 'react-easy-scroll-effect/dist/index.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MyAppBar from '../controller/appbar';
import ScrollTo from '../model/scroll_to';

function HomePage() {

    const [animatedText, setAnimatedText] = React.useState("");

    // Do this on screen load
    React.useEffect(() => {

    }, [])

    return (
        <div className="App">
            <MyAppBar />
            <header className="App-header" id="header">
                <h1 className="gradient-text2" style={{ zIndex: '2' }}>Behind every great business is a great bookkeeper!<p>Let me focus on your bookkeeping so you can focus on your business.</p></h1>
                <div style={{ display: 'flex' }}>
                    <img src="images/quickbooks.png" style={{ width: '85px' }} />
                    <img src="images/aipb.png" style={{ width: '85px' }} />
                </div>
            </header>

            <body>
                <div className="App-features" id="features">
                    <h2 className="gradient-text" style={{ marginBottom: '10px' }}>SERVICES</h2>
                    <span style={{ fontStyle: 'italics', color: 'grey' }}>My mission is to provide reliable, professional services that meet your business needs.</span>
                    <Grid container spacing={0} style={{ marginTop: '25px', width: '100%' }}>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className="feature">Bank Reconciliations</div>
                            <div className="feature">Accounts Payable</div>
                            <div className="feature">Accounts Receivable</div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className="feature">Payroll & Payroll Taxes</div>
                            <div className="feature">Sales & Use Taxes</div>
                            <div className="feature">Income Tax Quarterly Payments</div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className="feature">Financial Reports</div>
                            <div className="feature">QuickBooks Professional Advisor</div>
                            <div className="feature">QuickBooks Training</div>
                        </Grid>
                    </Grid>

                </div>

                <div style={{ width: '100%', maxWidth: '1080px', margin: '0 auto', marginTop: '60px', marginBottom: '75px', height: '1px', backgroundColor: 'rgba(0, 0, 0, 0.2)' }} />

                <div className="App-about" id="about">


                    <div className="portfolio-item" style={{ marginTop: '75px' }}>
                        <div className="portfolio-bg" style={{ zIndex: '1' }} />

                        <Grid container spacing={2}>

                            <Grid item sm={12}>
                                <div className="portfolio-image">
                                    <h2 className="gradient-text" style={{ paddingTop: '25px', fontSize: '24px', margin: '10px' }}>Read about me in the Midland Daily News,<br/><a href="https://www.ourmidland.com/news/article/dck-bookkeeping-services-midland-keeps-diverse-19597046.php" target="_blank" style={{ fontSize: '24px', color: 'green' }}>click here.</a></h2>
                                    <img src="images/danielle.png" style={{ width: '50%', borderRadius: '100%', padding: '50px' }} /><br />
                                    <span style={{ fontStyle: 'italic' }}>Danielle Bessemer</span>
                                    <h2 style={{ fontStyle: 'italic', fontWeight: 'bold', paddingBottom: '25px', margin: '10px' }}>Serving the Mid-Michigan area for over 25 years!</h2>
                                    <p style={{paddingBottom: '20px'}}><a href="https://www.linkedin.com/in/danielle-bessemer-47560a47/" target="_blank"><img src="images/linkedin.png"/></a></p>
                                </div>

                            </Grid>

                        </Grid>

                    </div>


                    {/* <div style={{ width: '75%', maxWidth: '1080px', marginTop: '75px', marginBottom: '75px', height: '1px', backgroundColor: 'rgba(0, 0, 0, 0.2)' }} /> */}

                </div>

                <div style={{ width: '100%', maxWidth: '1080px', margin: '0 auto', marginTop: '60px', marginBottom: '75px', height: '1px', backgroundColor: 'rgba(0, 0, 0, 0.2)' }} />


                <div className="App-about" id="contact" style={{marginBottom: '50px'}}>


                <div className="portfolio-item" style={{ marginTop: '75px' }}>
                    <div className="portfolio-bg" style={{ zIndex: '1' }} />

                        <iframe 
                            src="https://form.jotform.com/250047709129154" 
                            style={{width: '100%', height: '1000px', border: 'none'}}
                            title="No Border Iframe">
                        </iframe>

                </div>


                {/* <div style={{ width: '75%', maxWidth: '1080px', marginTop: '75px', marginBottom: '75px', height: '1px', backgroundColor: 'rgba(0, 0, 0, 0.2)' }} /> */}

                </div>

                <header><a href="https://kyleb.app" target="_blank" style={{ padding: '20px', color: 'grey', fontSize: '10px' }}>Website developed by kyleb.app</a></header>

            </body>
        </div>
    );
}

export default HomePage;
