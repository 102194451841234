import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
// import { makeStyles } from '@material-ui/core/styles';
import ScrollTo from '../model/scroll_to';

export default function MyAppBar() {

  window.onresize = GetScreenWidth;
  const [screenWidth, setScreenWidth] = React.useState("big");

  React.useEffect(() => {
    GetScreenWidth();
  }, [])

  function GetScreenWidth() {
    console.log(window.innerWidth);
    if (window.innerWidth <= 800) {
      setScreenWidth("small");
    } else {
      setScreenWidth("big");
    }
  }
  return (
    screenWidth == "big"
      ?  <Box sx={{ flexGrow: 1 }}>
      <div sx={{
        bgcolor: '#fff',
      }} position="fixed">
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '75%', margin: '0 auto' }}>
          <a href="#header" style={{marginTop: '15px'}}><span style={{fontStyle: 'italic', fontWeight: 'bold'}}>DCK Bookkeeping Services Inc<br/><span style={{fontWeight: 'normal'}}>(989) 486-8170</span></span></a>
          <div style={{ display: 'flex' }}>
            {screenWidth == "big"
              ? <div className="links">
                <a style={{ cursor: 'pointer', margin: '10px' }} onClick={() => ScrollTo("features")} className="link-item">Services</a>
                <a style={{ cursor: 'pointer', margin: '10px'  }} onClick={() => ScrollTo("about")} className="link-item">About</a>
                <a style={{ cursor: 'pointer', margin: '10px'  }} onClick={() => ScrollTo("contact")} className="link-item">Contact</a>
              </div>
              : null
            }
          </div>
        </div>
      </div>
    </Box>
      : <Box sx={{ flexGrow: 1 }}>
      <div sx={{
        bgcolor: '#fff',
      }} position="fixed">
        <div style={{ width: '100%', margin: '0 auto' }}>
          <a href="#header" style={{marginTop: '15px'}}><span style={{marginTop: '5px', fontStyle: 'italic', fontWeight: 'bold'}}>DCK Bookkeeping Services Inc<br/><span style={{fontWeight: 'normal'}}>(989) 486-8170</span></span></a>
        </div>
      </div>
    </Box>
  );
}